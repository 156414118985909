// require('../scss/tailwind.css');
// fontAwesome
require('../scss/stagiaires.scss');

// js
window.$ = window.jQuery = require('jquery');

require('./stagiaires/layout');
require('./stagiaires/timer');
require('./utils/common');
require('./utils/audio');

$(() => {
  let intervalHandler;

  // Rafraîchissement automatique
  const refreshInterval = () => {
    $('.auto-refresh').each(function () {
      const el = $(this);
      intervalHandler = setInterval(() => {
        $.get(el.data('href'), (data) => {
          el.html(data);
        });
      }, 5000);
    });
  };

  const fadeToast = (item) => {
    item.addClass('hidden');
  };

  $('.tw-toast-autohide').each(function () {
    const item = $(this);
    setTimeout(() => {
      fadeToast(item);
    }, 3000);
  });

  $('.tw-toast-close').on('click', function () {
    fadeToast($(this).parents('.alert'));
  });

  const togglePassword = $('#password-toggle');

  togglePassword.on('click', () => {
    const passwordInput = $('#password');
    const type = passwordInput.attr('type') === 'password' ? 'text' : 'password';
    passwordInput.attr('type', type);
    togglePassword.children('i').toggleClass('fa-eye-slash fa-eye');
  });

  $('div.btn-div').on('click', function (e) {
    if (e.target.tagName === 'A') {
      return;
    }
    window.location.href = $(this).data('href');
  });

  $(document).on('click', '#link-start-modal', function () {
    $('#start-test').attr('href', $(this).data('href'));
    document.getElementById('start_modal').showModal();
    clearInterval(intervalHandler);
    $('#player-audio')[0]?.play();
  });

  $('#start_modal').on('close', function () {
    $('#player-audio')[0]?.pause();
    refreshInterval();
  });

  refreshInterval();

  // Barre de progression
  $('footer > .progress').each(function () {
    const progress = $(this);
    let seconds = progress.data('seconds');
    const total = progress.data('total');

    setInterval(() => {
      if (seconds === 0) {
        window.location.href = window.location.href.replace('?start=true', '');
        return;
      }
      seconds--;
      progress.css('width', `${(seconds * 100) / total}%`);
    }, 1000);
  });

  // Gestion de formulaire avec AJAX
  $('#question-form button[type=submit]').on('click', function (e) {
    e.preventDefault();
    const url = $(this).closest('form').attr('action');
    const value = $(this).attr('value');

    $.post(url, { answer_id: value }, (response) => {
      if (response.saved) {
        $(this).closest('form').find('button').addClass('btn-outline');
        $(this).removeClass('btn-outline');
        $('#question-form button').prop('disabled', false);
      }
    });

    return false;
  });


  // Initial Test
  $(document).on('click', '#submit-answers', function () {

    const checkboxes = $('#quiz input[type="checkbox"], #quiz input[type="radio"]');
    let correctAnswers = 0;
    let totalCorrect = 0;
    let selectedIncorrect = false;
    const currentStep = $('#quiz').data('current-step');

    checkboxes.each(function () {
      const isCorrect = $(this).data('is-correct') === 1;
      const isChecked = $(this).prop('checked');
      const answerLabel = $(this).siblings('label');

      if (isCorrect) totalCorrect++;
      if (isChecked) {
        if (isCorrect) {
          correctAnswers++;
          if (currentStep === 0) {
            answerLabel.addClass('text-success');
          }
        } else {
          selectedIncorrect = true;
          if (currentStep === 0) {
            answerLabel.removeClass('text-gray-900').addClass('text-error line-through');
          }
        }
      } else if (isCorrect) {
        if (currentStep === 0)
          answerLabel.addClass('text-success');
      }
    });

    const resultContainer = $('#result-message');
    if (correctAnswers === totalCorrect && !selectedIncorrect) {
      resultContainer
        .text("Bonne réponse")
        .removeClass('bg-error')
        .addClass('bg-success');
    } else {
      const missingAnswers = totalCorrect - correctAnswers;
      const incorrectText = selectedIncorrect ? "Vous avez sélectionné des réponses incorrectes." : "";
      const missingText = missingAnswers > 0 ? `Il vous manque ${missingAnswers} bonne(s) réponse(s).` : "";

      resultContainer
        .html(`Mauvaise réponse! ${incorrectText} ${missingText}`)
        .removeClass('bg-success')
        .addClass('bg-error');
    }

    $('.bg-gray-200').removeClass('hidden');
    $('#submit-answers').addClass('hidden');
    $('#next-question').removeClass('hidden');
  });

  // Gestion des onglets
  $('.nav-link').on('click', function (e) {
    e.preventDefault();
    $('.nav-link').removeClass('active text-certigo-dark border-certigo-dark')
      .addClass('text-gray-600 border-transparent');
    $('.tab-pane').addClass('hidden').css('display', 'none');
    $(this).addClass('active text-certigo-dark border-certigo-dark')
      .removeClass('text-gray-600 border-transparent');
    const tabId = $(this).attr('href').substring(1);
    $(`#${tabId}`).removeClass('hidden').css('display', 'block');
  });

  const defaultTab = $('.nav-link.active').attr('href')?.substring(1) || 'tab-1';
  $(`#${defaultTab}`).removeClass('hidden').css('display', 'block');
});


// Importation de IdleJs pour la gestion de l'inactivité
import Player from '@vimeo/player';
import IdleJs from 'idle-js';

$(() => {
  
  let status = 'active';
  let videoPlayer = null;

  if ($('#training-content').length === 0) {
    return;
  }

  const checkVideoProgress = async () => {
    if (!videoPlayer) {
      return
    }
    const duration = await videoPlayer.getDuration();
    const currentTime = await videoPlayer.getCurrentTime();
    const progress = (currentTime / duration) * 100;
    if (progress >= 70) {
      return true;
    }
    return false;
  };

  const isVideoPlaying = async () => {
    if (videoPlayer) {
      status = await videoPlayer.getPaused();
    }
  };
  
  // Initialize the Vimeo player
  const iframe = document.querySelector('iframe');
  
  if (iframe) {
    videoPlayer = new Player(iframe);
  }
  
  new IdleJs({
    // idle: 900000, // 15 minutes d'inactivité
    idle: 60000, // 5 minutes d'inactivité
    onIdle: async () => {
      console.log('idle');
      status = 'idle';
    }, // Mettre le timer en pause
    onActive: () => {
      console.log('active');
      status = 'active';
      // startTimer(null), // Redémarrer le timer quand l'utilisateur devient actif
    }
  }).start();


  setInterval(async() => {

    const playing = await isVideoPlaying();
    const seen = await checkVideoProgress();
    if (seen) {
      const url = $('#video-list').data('url');
      const test = await $.get(url);

      const { isValid } = test;
      const element = $('#test-final');
      element.removeClass('bg-gray-100 cursor-not-allowed text-gray-400')
       .addClass(isValid ? '' : 'bg-gray-100 cursor-not-allowed text-gray-400')
       .attr('onclick', isValid ? '' : 'return false;');
    }

    if (status === 'active'  || playing) {
      // ping server
      const url = $('#training-content').data('url');
      $.get(url);
    }

  }, 5000);

});
